import React from 'react';
import { Box } from '@mui/material';
import { SGridToolbarQuickFilter } from '../../styles/style.js';

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        width: '100%',
      }}
      className="no-print"
    >
      <SGridToolbarQuickFilter placeholder="Search Transactions..." />
    </Box>
  );
}

export default QuickSearchToolbar;
