import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container, } from '@mui/material';
import axiosInstance from '../../../api/axiosWrapper';
import { WebSocketContext } from '../../common/contexts/WebSocketContext';
import { useNavigate } from 'react-router-dom';
import LinkAccountsPage from './LinkAccountsPage';
import { AiOutlineBank, AiOutlineCheckCircle } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { colors } from '../../../themes/theme';
import { GradientProgress } from '../../styles/style';


const interactiveTexts = [

  { content: 'ONEBIT makes managing your finances as simple as pie! 🥧' },
  { content: 'We’re your financial buddy! 🤝 Let us handle the tough work.' },
  { content: 'Smarter decisions start with better insights. 📈' },
  { content: 'Track spending, expenses, and financial health effortlessly. 🔍' },
  { content: 'Monitor net profit and loss trends. 📉📈' },
  { content: 'Integrates with your POS systems for seamless management. 📊' },
  { content: 'Your finances are protected with encrypted data. 🛡️' },
];

const LinkBankAccountPage = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const [syncing, setSyncing] = useState(false);
  const [syncCompleted, setSyncCompleted] = useState(false);
  const [loadingActive, setLoadingActive] = useState(false);
  const [linkProcessStarted, setLinkProcessStarted] = useState(false);
  const { isConnected, wsRef } = useContext(WebSocketContext);
  const [progress, setProgress] = useState(0);
  const [progressCapped, setProgressCapped] = useState(false);
  const [currentText, setCurrentText] = useState(0);
  const navigate = useNavigate();

  // Fetch Plaid link token
  const fetchLinkToken = async () => {
    try {
      const { data } = await axiosInstance.post('/createPlaidLinkToken');
      if (data.success) {
        setLinkToken(data.data.linkToken.link_token);
        localStorage.setItem('link_token', data.data.linkToken.link_token);
      } else {
        console.error('Failed to create Plaid link token');
      }
    } catch (error) {
      console.error('Error fetching link token:', error);
    }
  };

  // Simulated progress for syncing
  useEffect(() => {
    let interval;
    if (syncing && !syncCompleted) {
      interval = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 95) {
            setProgressCapped(true);
            clearInterval(interval);
            return 95;
          }
          let increment;
          if (oldProgress < 30) {
            increment = 4;
          } else if (oldProgress >= 30 && oldProgress < 90) {
            increment = 3;
          } else {
            increment = 1;
          }
          return Math.min(oldProgress + increment, 95);
        });
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [syncing, syncCompleted]);

  // WebSocket to handle syncing process
  useEffect(() => {
    if (isConnected && wsRef.current) {
      const ws = wsRef.current;

      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log('WebSocket message received:', message);

        if (message.status === 'syncing') {
          setSyncing(true);
        } else if (message.status === 'complete') {
          setSyncing(false);
          setSyncCompleted(true);
          setProgress(100);
        }
      };

      return () => {
        ws.onmessage = null;
      };
    }
  }, [isConnected, wsRef]);

  // Handle loadingActive behavior based on loadingData, syncing, and link process state
  useEffect(() => {
    if (loadingData && !syncing) {
      setLoadingActive(true);
    } else if (!loadingData && syncing) {
      setLoadingActive(true);
    } else if (!loadingData && !syncing && syncCompleted) {
      setLoadingActive(false);
    }
  }, [loadingData, syncing, syncCompleted]);

  useEffect(() => {
    if (syncing) {
      const textInterval = setInterval(() => {
        setCurrentText((prevText) => (prevText + 1) % interactiveTexts.length);
      }, 12000);

      return () => clearInterval(textInterval);
    }
  }, [syncing]);

  useEffect(() => {
    if (syncCompleted) {
      setTimeout(() => {
        window.location.href = '/dashboard';
      }, 2000);
    }
  }, [syncCompleted, navigate]);

  const handleLinkSuccess = () => {
    setLoadingData(true);
    setLinkProcessStarted(true);
  };

  useEffect(() => {
    const storedLinkToken = localStorage.getItem('link_token');
    if (storedLinkToken) {
      setLinkToken(storedLinkToken);
    } else {
      fetchLinkToken();
    }
  }, []);

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="calc(100vh - 64px)"
        position="relative"
      >
        {linkToken && !loadingActive && !linkProcessStarted && !syncCompleted ? (
          <LinkAccountsPage
            loadPlaidWidget={true}
            key={linkToken}
            linkToken={linkToken}
            loadingData={loadingData}
            setLoadingData={setLoadingData}
            onSuccess={handleLinkSuccess}
          />
        ) : (
          !syncCompleted && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ ease: 'linear', duration: 4, repeat: Infinity }}
                style={{ marginBottom: '20px' }}
              >
                <AiOutlineBank size={60} color={colors.primary} />
              </motion.div>

              <Typography variant="h6" sx={{ mt: 1 }}>
                {progress < 20 && 'Starting the sync process... '}
                {progress >= 20 &&
                  progress < 50 &&
                  'Hold tight, syncing your data... '}
                {progress >= 50 &&
                  progress < 80 &&
                  'We’re getting all your details ready... '}
                {progress >= 80 &&
                  progress < 95 &&
                  'Almost done, crunching those numbers... '}
                {progress === 95 &&
                  !progressCapped &&
                  'Almost done, finalizing... '}
                {progressCapped && 'Finalizing the sync... '}
              </Typography>

              <motion.div
                key={currentText}
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ duration: 2 }}
                style={{ textAlign: 'center', marginBottom: '10px' }}
              >
                <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>
                  {syncing
                    ? interactiveTexts[currentText].content
                    : 'This may take a few moments. Please do not close the browser.'}
                </Typography>
              </motion.div>

              <GradientProgress 
                variant="determinate"
                value={progress}
                sx={{ width: '100%', mt: 2 }}
              />
            </Box>
          )
        )}

        {syncCompleted && (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 120, duration: 0.8 }}
            style={{
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              color={colors.primary}
            >
              <AiOutlineCheckCircle size={80} />
              <Typography variant="h6" sx={{ color: colors.black, mt: 2 }}>
                Transactions Synced!
              </Typography>
            </Box>
          </motion.div>
        )}
      </Box>
    </Container>
  );
};

export default LinkBankAccountPage;