import React from 'react';
import DateRangeComponent from './DateRangeComponent.js';
import dayjs from 'dayjs';
import { IndicatorBox } from '../../styles/style.js';
import { useMediaQuery } from '@mui/material';
import { colors } from '../../../themes/theme.js';

const DateRangeIndicator = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  minDateRangeLocal,
  maxDateRangeLocal,
  handleReset,
  onCustomDateRangeSelect,
  isCustomRange,
}) => {
  const isBetween900And970 = useMediaQuery(
    '(min-width:900px) and (max-width:970px)'
  );

  const isResetDisabled =
    dayjs(startDate).isSame(dayjs(minDateRangeLocal), 'day') &&
    dayjs(endDate).isSame(dayjs(maxDateRangeLocal), 'day');

  const handleDateChange = (newStartDate, newEndDate) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    onCustomDateRangeSelect(newStartDate, newEndDate);
  };

  const handleResetClick = () => {
    handleReset();
  };

  return (
    <IndicatorBox
      borderRadiusProp={isBetween900And970 ? '18px' : '50px'}
      sx={{
        maxWidth: '400px',
        marginBottom: '10px',
        marginRight: { xs: '-35px', md: '0' },
        paddingLeft: '10px',
        paddingRight: '5px',
        display: 'flex',
        flexDirection: { md: 'column' },
        alignItems: isBetween900And970 ? 'center' : 'flex-start',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        borderColor: !isResetDisabled ? colors.secondary : 'transparent',
        borderWidth: !isResetDisabled ? '1px' : '0',
        borderStyle: !isResetDisabled ? 'solid' : 'none',
        transition: 'background-color 0.3s ease',
      }}
    >
      <DateRangeComponent
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        minDateRange={minDateRangeLocal}
        maxDateRange={maxDateRangeLocal}
        handleReset={handleResetClick}
        isResetDisabled={isResetDisabled}
        onDateChange={handleDateChange}
      />
    </IndicatorBox>
  );
};

export default React.memo(DateRangeIndicator);
