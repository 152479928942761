import React from 'react';
import {
  SFormControl,
  SInputLabel,
  SSelect,
  SMenuItem,
} from '../../styles/style';
import { useMediaQuery, useTheme } from '@mui/material';

const TransactionFilter = ({ toggleValue, handleToggleChange }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <SFormControl
      sx={{
        m: isDesktop ? 1 : 0,
        width: isDesktop ? '180px' : '100%',
        '& .MuiInputBase-root': {
          height: '40px',
        },
      }}
      size="small"
    >
      <SInputLabel id="toggle-select-small-label">Filters</SInputLabel>
      <SSelect
        labelId="toggle-select-small-label"
        id="toggle-select-small"
        value={toggleValue}
        label="Filters"
        onChange={handleToggleChange}
        color="primary"
      >
        <SMenuItem value="All">All</SMenuItem>
        <SMenuItem value="income">Income</SMenuItem>
        <SMenuItem value="expenses">Expenses</SMenuItem>
      </SSelect>
    </SFormControl>
  );
};

export default TransactionFilter;
