import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { colors } from '../../../themes/theme.js';
import dayjs from 'dayjs';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const shortcutsItems = [
  { label: 'Week', value: '7days' },
  { label: 'Month', value: '30days' },
  { label: 'Quarter', value: '90days' },
  { label: 'Year', value: '365days' },
  { label: 'All', value: 'all' },
];

function TimeTabsModal({
  minDateRangeLocal,
  maxDateRangeLocal,
  handleReset,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  closeModal,
  onMobileShortcutSelect,
  onCustomDateRangeSelect,
  clearDesktopFilters,
  modalOpen,
}) {
  const [dateRange, setDateRange] = useState([
    dayjs(startDate),
    dayjs(endDate),
  ]);
  const [selectedShortcut, setSelectedShortcut] = useState(() => {
    return localStorage.getItem('lastMobileShortcut') || 'all';
  }); // Tracks selected shortcut

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Helper function to adjust the date range based on the allowed limits
  const adjustDateRange = useCallback(
    (range) => {
      const [start, end] = range;
      return [
        start.isBefore(minDateRangeLocal) ? dayjs(minDateRangeLocal) : start,
        end.isAfter(maxDateRangeLocal) ? dayjs(maxDateRangeLocal) : end,
      ];
    },
    [minDateRangeLocal, maxDateRangeLocal]
  );

  // Updates the date range and invokes callback to set global state
  const updateDates = useCallback(
    (adjustedRange) => {
      setDateRange(adjustedRange);
      setStartDate(adjustedRange[0].format('YYYY-MM-DD'));
      setEndDate(adjustedRange[1].format('YYYY-MM-DD'));
    },
    [setStartDate, setEndDate]
  );

  const handleDateRangeChange = useCallback(
    (newDateRange) => {
      const adjustedRange = adjustDateRange(newDateRange);
      updateDates(adjustedRange);
      onCustomDateRangeSelect(
        adjustedRange[0].format('YYYY-MM-DD'),
        adjustedRange[1].format('YYYY-MM-DD')
      );
    },
    [adjustDateRange, updateDates, onCustomDateRangeSelect]
  );
  const handleShortcutClick = useCallback(
    (shortcut) => {
      setSelectedShortcut(shortcut);
      localStorage.setItem('lastMobileShortcut', shortcut); // Update local state to show selected shortcut
      if (shortcut === 'all') {
        handleReset(); // Reset all filters for 'All'
      } else {
        onMobileShortcutSelect(shortcut);
      }
    },
    [handleReset, onMobileShortcutSelect]
  );

  // When startDate or endDate changes, adjust the date range and update state
  useEffect(() => {
    const adjustedRange = adjustDateRange([dayjs(startDate), dayjs(endDate)]);
    updateDates(adjustedRange);
  }, [adjustDateRange, startDate, endDate, updateDates]);

  // When switching between mobile and desktop, clear filters only if switching
  useEffect(() => {
    if (isMobile && !modalOpen) {
      clearDesktopFilters(); // Only clear desktop filters on mobile view switch
    }
  }, [isMobile, modalOpen, clearDesktopFilters]);

  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
      }}
    >
      {isMobile && (
        <IconButton
          onClick={closeModal}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: colors.primary,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}

      {/* Date Picker */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDateRangePicker
          calendars={1}
          value={dateRange}
          onChange={handleDateRangeChange}
          minDate={dayjs(minDateRangeLocal)}
          maxDate={dayjs(maxDateRangeLocal)}
          slotProps={{ actionBar: { actions: [] } }}
          sx={datePickerStyles}
        />
      </LocalizationProvider>

      {/* Shortcut Buttons */}
      <Box sx={shortcutButtonsContainerStyles}>
        {shortcutsItems.map((shortcut) => (
          <Button
            key={shortcut.value}
            variant="contained"
            size="small"
            onClick={() => handleShortcutClick(shortcut.value)}
            sx={getShortcutButtonStyles(selectedShortcut === shortcut.value)}
          >
            {shortcut.label}
          </Button>
        ))}
      </Box>

      {/* Close Button (visible only on tablet and larger screens) */}
      {!isMobile && (
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Button
            variant="outlined"
            onClick={closeModal}
            sx={closeButtonStyles}
          >
            Close
          </Button>
        </Box>
      )}
    </Box>
  );
}

/** Date Picker Styles */
const datePickerStyles = {
  '.MuiDateRangePickerViewDesktop-calendar': {
    minWidth: 'auto',
    maxWidth: '100%',
  },
  '.MuiPickersCalendarHeader-root': {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  '.MuiDayCalendar-weekDayLabel': {
    width: '32px',
  },
  '.MuiPickersDay-root': {
    width: '32px',
    height: '32px',
  },
};

/** Styles for the Shortcut Buttons container */
const shortcutButtonsContainerStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  width: '100%',
  mt: 2,
  gap: 1,
};

/** Function to apply dynamic styles to Shortcut Buttons */
const getShortcutButtonStyles = (isSelected) => ({
  borderRadius: 50,
  textTransform: 'none',
  backgroundColor: isSelected ? colors.secondary : '#1360EF',
  color: 'white',
  '&:hover': {
    backgroundColor: colors.secondary,
    cursor: 'pointer',
  },
  flexGrow: 1,
  maxWidth: 'calc(20% - 8px)',
  minWidth: '80px',
});

/** Styles for the Close Button */
const closeButtonStyles = {
  mt: 2,
  width: '50%',
  textTransform: 'none',
  justifyContent: 'center',
  borderColor: colors.primary,
  color: colors.primary,
  '&:hover': {
    borderColor: colors.bgGray,
    backgroundColor: colors.lightGray,
    color: colors.bgGray,
  },
};

export default React.memo(TimeTabsModal);
