export const colors = {
  primary: '#1360EF',
  primaryHover: '#1360EFD9',
  secondary: '#F85014',
  success: '#00C853',
  warning: '#FFD600',
  error: '#FF1744',
  info: '#00B0FF',
  light: '#F5F5F5',
  dark: '#263238',
  white: '#FFFFFF',
  black: '#000000',
  black2: '#060928',
  gray: '#D4D4D0',
  lightGray: '#FBFBFB',
  lightGray2: '#F0F0F0',
  lightGray3: '#888888',
  darkGray: '#A9A9A9',
  bgGray: '#c8c5c5',
  darkGray2: '#434343',
  transparent: 'transparent',
  gradient: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  shadow: '1px 1px 3px rgba(0, 0, 0, 0.2)',
  expenses: '#f22233',
  income: '#03a63c',
  borderColor: '#00000036',
  hoverBg: '#303f9f',
  buttonBg: '#007bff',
  darkGray3: '#333333',
  mediumGray: '#666666',
  lightGray4: '#999999',
  ebtext: '#2A2A2A',
  receiptButtonGrey: `#ececec`,
  ebbackground: '#FFF0F0',
};

export const categoriesColorArray = [
  '#e0f7fa',
  '#f0f4c3',
  '#fff8e1',
  '#f3e5f5',
  '#e8f5e9',
  '#fce4ec',
  '#e0f2f1',
  '#fff3e0',
  '#f1f8e9',
  '#f9fbe7',
  '#ede7f6',
  '#e8eaf6',
  '#e3f2fd',
  '#fbe9e7',
  '#f5f5f5',
  '#e6ee9c',
  '#d1c4e9',
  '#c5cae9',
  '#ffccbc',
  '#d7ccc8',
  '#ffeb3b',
  '#ffe0b2',
  '#c8e6c9',
  '#bbdefb',
  '#ffecb3',
  '#f8bbd0',
  '#ffccbc',
  '#e1bee7',
  '#c5e1a5',
  '#b3e5fc',
  '#ffcc80',
  '#d7ccc8',
];

export const pieChart = {
  pieChartColors: ['#26C0C7', '#D35151', '#E68618', '#5151D3', '#1360EF'],
  primary: '#4B4B4B',
  secondary: '#999999',
};
