import React, { useMemo, useState, useCallback, useEffect } from 'react';
import {
  Box,
  Modal,
  Tab,
  MenuItem,
  IconButton,
  useMediaQuery,
  useTheme,
  Checkbox,
  Snackbar,
  Alert,
} from '@mui/material';
import dayjs from 'dayjs';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  CustomTabs,
  ValueTypography,
  ItemFieldset,
  ItemLegend,
  StyledIconButton,
  StyledSelect,
} from '../../styles/style.js';
import { colors } from '../../../themes/theme.js';
import { useFinancialMetrics } from '../../common/hooks/useFinancialMetrics.js';
import { useDashboardContext } from '../../common/contexts/DashboardContext.js';
import {
  generateWeekOptions,
  generateMonthOptions,
  generateQuarterOptions,
  generateYearOptions,
  handleWeekSelectionChange,
  handleMonthSelectionChange,
  handleQuarterSelectionChange,
  handleYearSelectionChange,
} from './dateOptions';
import DateRangeIndicator from './DateRangeIndicator';
import { useDateState } from '../../common/hooks/useDateState.js';
import TimeTabsModal from './TimeTabsModal';

const MetricsDisplay = React.memo(
  ({ transactions, onTimePeriodChange, filterState }) => {
    // Context and state management hooks
    const {
      activeTimeTab,
      setActiveTimeTab,
      selectedWeeks,
      setSelectedWeeks,
      selectedMonths,
      setSelectedMonths,
      selectedQuarters,
      setSelectedQuarters,
      selectedYears,
      setSelectedYears,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      minDateRangeLocal,
      maxDateRangeLocal,
    } = useDashboardContext();

    const dateState = useDateState();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [hoveredTab, setHoveredTab] = useState(null);
    const [isCustomDateRange, setIsCustomDateRange] = useState(false);
    const [mobileShortcut, setMobileShortcut] = useState(() => {
      return localStorage.getItem('lastMobileShortcut') || null;
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [hasClearedDesktopFilters, setHasClearedDesktopFilters] =
      useState(false);
    const [prevIsMdOrBelow, setPrevIsMdOrBelow] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdOrBelow = useMediaQuery(theme.breakpoints.down('md'));

    const clearDesktopFilters = useCallback(() => {
      // Clear all desktop-specific filters when switching to mobile view
      setSelectedWeeks([]);
      setSelectedMonths([]);
      setSelectedQuarters([]);
      setSelectedYears([]);
      setActiveTimeTab('all');
      setSnackbarMessage('Switched to mobile view, desktop filters cleared.');
      setSnackbarOpen(true);
      setHasClearedDesktopFilters(true);
    }, [
      setSelectedWeeks,
      setSelectedMonths,
      setSelectedQuarters,
      setSelectedYears,
      setActiveTimeTab,
    ]);

    useEffect(() => {
      // Check if this is the first render or a refresh
      if (prevIsMdOrBelow === null) {
        setPrevIsMdOrBelow(isMdOrBelow);
        return;
      }

      // Only clear filters and show alert when transitioning from desktop to mobile
      if (isMdOrBelow && !prevIsMdOrBelow && !hasClearedDesktopFilters) {
        clearDesktopFilters();
      } else if (!isMdOrBelow) {
        // Reset the flag when switching back to desktop
        setHasClearedDesktopFilters(false);
      }

      setPrevIsMdOrBelow(isMdOrBelow);
    }, [
      isMdOrBelow,
      clearDesktopFilters,
      hasClearedDesktopFilters,
      prevIsMdOrBelow,
    ]);

    useEffect(() => {
      // Reset hasClearedDesktopFilters on page refresh
      const handleBeforeUnload = () => {
        localStorage.setItem('hasClearedDesktopFilters', 'false');
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      // Check if we're coming from a refresh
      const storedHasClearedDesktopFilters = localStorage.getItem(
        'hasClearedDesktopFilters'
      );
      if (storedHasClearedDesktopFilters === 'false') {
        setHasClearedDesktopFilters(false);
      }

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, []);

    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };

    const handleClick = useCallback(
      (event) => {
        const targetClassName = event.target.className;
        const isTargetBackdrop =
          typeof targetClassName === 'string' &&
          targetClassName.includes('MuiBackdrop-root') &&
          targetClassName.includes('MuiBackdrop-invisible') &&
          targetClassName.includes('MuiModal-backdrop');

        // If click is outside the modal (on backdrop), close the date selectors
        if (isTargetBackdrop) {
          dateState.handleCloseWeek();
          dateState.handleCloseMonth();
          dateState.handleCloseQuarter();
          dateState.handleCloseYear();
          event.stopPropagation();
        }
      },
      [dateState]
    );

    const weekOptions = useMemo(
      () =>
        generateWeekOptions(transactions, minDateRangeLocal, maxDateRangeLocal),
      [transactions, minDateRangeLocal, maxDateRangeLocal]
    );
    const monthOptions = useMemo(
      () =>
        generateMonthOptions(
          transactions,
          minDateRangeLocal,
          maxDateRangeLocal
        ),
      [transactions, minDateRangeLocal, maxDateRangeLocal]
    );
    const quarterOptions = useMemo(
      () =>
        generateQuarterOptions(
          transactions,
          minDateRangeLocal,
          maxDateRangeLocal
        ),
      [transactions, minDateRangeLocal, maxDateRangeLocal]
    );
    const yearOptions = useMemo(
      () =>
        generateYearOptions(transactions, minDateRangeLocal, maxDateRangeLocal),
      [transactions, minDateRangeLocal, maxDateRangeLocal]
    );

    const filteredTransactions = useMemo(() => {
      if (filterState === 'income') {
        return transactions.filter((transaction) => transaction.amount >= 0);
      } else if (filterState === 'expenses') {
        return transactions.filter((transaction) => transaction.amount <= 0);
      }
      return transactions;
    }, [transactions, filterState]);

    const { income, expenses, netProfit } = useFinancialMetrics(
      filteredTransactions,
      activeTimeTab,
      selectedWeeks,
      selectedMonths,
      selectedQuarters,
      selectedYears,
      startDate,
      endDate
    );
    const items = useMemo(
      () => [
        { legend: 'Income', value: income, color: colors.income },
        { legend: 'Expenses', value: expenses, color: colors.expenses },
        {
          legend: `Net ${netProfit.startsWith('-') ? 'Loss' : 'Profit'}`,
          value: netProfit,
          color: netProfit.startsWith('-') ? colors.expenses : colors.income,
        },
      ],
      [income, expenses, netProfit]
    );

    // Function to update the date range based on the selected filters
    const updateDateRange = useCallback(() => {
      const minDate = dayjs(minDateRangeLocal);
      const maxDate = dayjs(maxDateRangeLocal);

      const updateRangeForPeriod = (selected, unit) => {
        if (selected.length > 0) {
          const selectedDates = selected.map((option) => {
            let start, end;
            // Dynamically parse start and end dates based on the unit (week, month, quarter, year)
            switch (unit) {
              case 'week':
                [start, end] = option.split(' - ');
                start = dayjs(start, 'MMM D');
                end = dayjs(end, 'MMM D, YYYY');
                break;
              case 'month':
                start = dayjs(option, 'MMMM YYYY').startOf('month');
                end = dayjs(option, 'MMMM YYYY').endOf('month');
                break;
              case 'quarter':
                start = dayjs(option, '[Q]Q YYYY').startOf('quarter');
                end = dayjs(option, '[Q]Q YYYY').endOf('quarter');
                break;
              case 'year':
                start = dayjs(option, 'YYYY').startOf('year');
                end = dayjs(option, 'YYYY').endOf('year');
                break;
              default:
                console.warn(`Unknown unit: ${unit}`);
                break;
            }
            return { start, end };
          });

          // Ensure selected date ranges don't exceed the available date range
          let newStartDate = dayjs.max(
            dayjs.min(...selectedDates.map((d) => d.start)),
            minDate
          );
          let newEndDate = dayjs.min(
            dayjs.max(...selectedDates.map((d) => d.end)),
            maxDate
          );

          // Handle case where start date is after end date (rare, but needed for edge cases)
          if (newStartDate.isAfter(newEndDate)) {
            newStartDate = newEndDate;
          }

          return { newStartDate, newEndDate };
        }
        return null;
      };

      let newDateRange;
      switch (activeTimeTab) {
        case 'week':
          newDateRange = updateRangeForPeriod(selectedWeeks, 'week');
          break;
        case 'month':
          newDateRange = updateRangeForPeriod(selectedMonths, 'month');
          break;
        case 'quarter':
          newDateRange = updateRangeForPeriod(selectedQuarters, 'quarter');
          break;
        case 'year':
          newDateRange = updateRangeForPeriod(selectedYears, 'year');
          break;
        case 'all':
          if (isCustomDateRange) {
            newDateRange = {
              newStartDate: dayjs(startDate),
              newEndDate: dayjs(endDate),
            };
          } else {
            newDateRange = { newStartDate: minDate, newEndDate: maxDate };
          }
          break;
        default:
          newDateRange = { newStartDate: minDate, newEndDate: maxDate };
          break;
      }

      if (newDateRange) {
        setStartDate(newDateRange.newStartDate.format('YYYY-MM-DD'));
        setEndDate(newDateRange.newEndDate.format('YYYY-MM-DD'));
      }
    }, [
      activeTimeTab,
      selectedWeeks,
      selectedMonths,
      selectedQuarters,
      selectedYears,
      minDateRangeLocal,
      maxDateRangeLocal,
      startDate,
      endDate,
      setStartDate,
      setEndDate,
      isCustomDateRange,
    ]);

    useEffect(() => {
      updateDateRange();
    }, [
      activeTimeTab,
      selectedWeeks,
      selectedMonths,
      selectedQuarters,
      selectedYears,
      minDateRangeLocal,
      maxDateRangeLocal,
      isCustomDateRange,
      updateDateRange,
    ]);

    const handleReset = useCallback(() => {
      setStartDate(dayjs(minDateRangeLocal).format('YYYY-MM-DD'));
      setEndDate(dayjs(maxDateRangeLocal).format('YYYY-MM-DD'));
      setIsCustomDateRange(false);
      setSelectedWeeks([]);
      setSelectedMonths([]);
      setSelectedQuarters([]);
      setSelectedYears([]);
      setActiveTimeTab('all');
      setMobileShortcut(null);
      localStorage.removeItem('lastMobileShortcut');
      onTimePeriodChange('all');
    }, [
      minDateRangeLocal,
      maxDateRangeLocal,
      setStartDate,
      setEndDate,
      setSelectedWeeks,
      setSelectedMonths,
      setSelectedQuarters,
      setSelectedYears,
      setActiveTimeTab,
      onTimePeriodChange,
    ]);

    const clearOtherSelections = useCallback(
      (currentTab) => {
        if (currentTab !== 'week') setSelectedWeeks([]);
        if (currentTab !== 'month') setSelectedMonths([]);
        if (currentTab !== 'quarter') setSelectedQuarters([]);
        if (currentTab !== 'year') setSelectedYears([]);
      },
      [
        setSelectedWeeks,
        setSelectedMonths,
        setSelectedQuarters,
        setSelectedYears,
      ]
    );

    const handleTimeTabChange = useCallback(
      (event, newValue) => {
        setActiveTimeTab(newValue);
        if (newValue === 'all') {
          handleReset();
        } else {
          setIsCustomDateRange(false);
          clearOtherSelections(newValue);
        }
        onTimePeriodChange(newValue);
        updateDateRange();
      },
      [
        setActiveTimeTab,
        onTimePeriodChange,
        updateDateRange,
        handleReset,
        clearOtherSelections,
      ]
    );

    const handleCustomDateRangeSelect = useCallback(
      (newStartDate, newEndDate) => {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setIsCustomDateRange(true);
        setActiveTimeTab('all');
        clearOtherSelections('all');
        onTimePeriodChange('all');
      },
      [
        setStartDate,
        setEndDate,
        setActiveTimeTab,
        clearOtherSelections,
        onTimePeriodChange,
      ]
    );

    const handleMobileShortcutSelect = useCallback(
      (shortcut) => {
        const maxDate = dayjs(maxDateRangeLocal);
        const minDate = dayjs(minDateRangeLocal);
        let newStartDate, newEndDate;

        // Set start and end dates for different mobile shortcut options (e.g., 7, 30, 90, 365 days)
        switch (shortcut) {
          case '7days':
            newStartDate = dayjs.max(maxDate.subtract(6, 'day'), minDate);
            newEndDate = maxDate;
            break;
          case '30days':
            newStartDate = dayjs.max(maxDate.subtract(29, 'day'), minDate);
            newEndDate = maxDate;
            break;
          case '90days':
            newStartDate = dayjs.max(maxDate.subtract(89, 'day'), minDate);
            newEndDate = maxDate;
            break;
          case '365days':
            newStartDate = dayjs.max(maxDate.subtract(364, 'day'), minDate);
            newEndDate = maxDate;
            break;
          case 'all':
            newStartDate = minDate;
            newEndDate = maxDate;
            break;
          default:
            return;
        }

        setStartDate(newStartDate.format('YYYY-MM-DD'));
        setEndDate(newEndDate.format('YYYY-MM-DD'));
        setIsCustomDateRange(false); // Ensure it's not a custom date range
        setMobileShortcut(shortcut);
        localStorage.setItem('lastMobileShortcut', shortcut);
      },
      [maxDateRangeLocal, minDateRangeLocal, setStartDate, setEndDate]
    );

    useEffect(() => {
      if (mobileShortcut && mobileShortcut !== 'all') {
        handleMobileShortcutSelect(mobileShortcut);
      }
    }, [handleMobileShortcutSelect, mobileShortcut]);

    const handlePrev = useCallback(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? items.length - 1 : prevIndex - 1
      );
    }, [items.length]);

    const handleNext = useCallback(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === items.length - 1 ? 0 : prevIndex + 1
      );
    }, [items.length]);

    const openModal = useCallback(() => setModalOpen(true), []);
    const closeModal = useCallback(() => setModalOpen(false), []);

    const renderTabContent = useCallback(
      (
        label,
        isOpen,
        handleOver,
        selectedValues,
        setSelectedValues,
        options
      ) => (
        <Tab
          sx={{
            width: 'fit-content',
            minWidth: 'unset',
            '&.MuiTab-root': {
              padding: 0,
            },
          }}
          label={
            <StyledSelect
              sx={{
                zIndex: hoveredTab === label ? 1301 : 'auto',
                width: '100%',
                minWidth: '100%',
                maxWidth: '100%',
                padding: 0,
              }}
              open={isOpen}
              onMouseEnter={() => {
                setHoveredTab(label);
                handleOver();
              }}
              multiple
              value={selectedValues}
              onChange={(event) => {
                switch (label) {
                  // Handle selection changes based on active tab (week, month, quarter, year)
                  case 'Week':
                    handleWeekSelectionChange(
                      event,
                      weekOptions,
                      setSelectedWeeks,
                      setSelectedMonths,
                      setSelectedQuarters,
                      setSelectedYears
                    );
                    break;
                  case 'Month':
                    handleMonthSelectionChange(
                      event,
                      monthOptions,
                      setSelectedWeeks,
                      setSelectedMonths,
                      setSelectedQuarters,
                      setSelectedYears
                    );
                    break;
                  case 'Quarter':
                    handleQuarterSelectionChange(
                      event,
                      quarterOptions,
                      setSelectedWeeks,
                      setSelectedMonths,
                      setSelectedQuarters,
                      setSelectedYears
                    );
                    break;
                  case 'Year':
                    handleYearSelectionChange(
                      event,
                      yearOptions,
                      setSelectedWeeks,
                      setSelectedMonths,
                      setSelectedQuarters,
                      setSelectedYears
                    );
                    break;
                  default:
                    console.warn(`Unknown label: ${label}`);
                    break;
                }

                clearOtherSelections(label.toLowerCase());
              }}
              onClick={handleClick}
              renderValue={() => label}
              displayEmpty
              MenuProps={{
                PaperProps: {
                  onMouseLeave: () => {
                    dateState[`handleClose${label}`]();
                    setHoveredTab(null);
                  },
                  style: {
                    maxHeight: 200,
                    maxWidth: 300,
                    fontSize: '14px',
                  },
                },
                BackdropProps: {
                  invisible: true,
                },
                autoFocus: false,
              }}
            >
              {selectedValues.length !== options.length ? (
                <MenuItem value="select-all">
                  <em>Select All</em>
                </MenuItem>
              ) : (
                <MenuItem value="deselect-all">
                  <em>Deselect All</em>
                </MenuItem>
              )}
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    sx={{ ml: -1 }}
                    checked={selectedValues.indexOf(option) > -1}
                  />
                  {option}
                </MenuItem>
              ))}
            </StyledSelect>
          }
          value={label.toLowerCase()}
        />
      ),
      [
        dateState,
        hoveredTab,
        clearOtherSelections,
        weekOptions,
        monthOptions,
        quarterOptions,
        yearOptions,
        setSelectedWeeks,
        setSelectedMonths,
        setSelectedQuarters,
        setSelectedYears,
        handleClick,
      ]
    );

    return (
      <>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="info"
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            width: '80vw',
            height: 'fit-content',
            backgroundColor: 'white',
            mb: 0,
            pb: { xs: 4, sm: 5, md: 6 },
            borderRadius: 6,
            boxShadow: colors.shadow,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {!isMdOrBelow ? (
            <Box
              sx={{
                width: '100%',
                position: 'relative',
                bottom: '14px',
                left: '35px',
              }}
            >
              <CustomTabs
                value={activeTimeTab}
                onChange={handleTimeTabChange}
                aria-label="time tabs"
                scrollButtons="auto"
                sx={{
                  '& .MuiTab-root': { minWidth: 'fit-content' },
                  cursor: 'pointer',
                }}
              >
                {/* Render Week, Month, Quarter, Year tabs */}
                {renderTabContent(
                  'Week',
                  dateState.isOpenWeek,
                  dateState.handleOverWeek,
                  selectedWeeks,
                  (event) =>
                    handleWeekSelectionChange(
                      event,
                      weekOptions,
                      setSelectedWeeks
                    ),
                  weekOptions
                )}
                {renderTabContent(
                  'Month',
                  dateState.isOpenMonth,
                  dateState.handleOverMonth,
                  selectedMonths,
                  (event) =>
                    handleMonthSelectionChange(
                      event,
                      monthOptions,
                      setSelectedMonths
                    ),
                  monthOptions
                )}
                {renderTabContent(
                  'Quarter',
                  dateState.isOpenQuarter,
                  dateState.handleOverQuarter,
                  selectedQuarters,
                  (event) =>
                    handleQuarterSelectionChange(
                      event,
                      quarterOptions,
                      setSelectedQuarters
                    ),
                  quarterOptions
                )}
                {renderTabContent(
                  'Year',
                  dateState.isOpenYear,
                  dateState.handleOverYear,
                  selectedYears,
                  (event) =>
                    handleYearSelectionChange(
                      event,
                      yearOptions,
                      setSelectedYears
                    ),
                  yearOptions
                )}
                <Tab
                  label="All"
                  value="all"
                  style={{ fontSize: '14px', flexShrink: 0 }}
                />
                <DateRangeIndicator
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  minDateRangeLocal={minDateRangeLocal}
                  maxDateRangeLocal={maxDateRangeLocal}
                  handleReset={handleReset}
                  onCustomDateRangeSelect={handleCustomDateRangeSelect}
                  isCustomRange={isCustomDateRange}
                />
              </CustomTabs>
            </Box>
          ) : (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'relative',
                bottom: '18px',
              }}
            >
              <IconButton
                onClick={openModal}
                sx={{
                  marginLeft: '-10px',
                  backgroundColor: colors.primary,
                  color: 'white',
                  borderRadius: '50%',
                  '&:hover': { backgroundColor: colors.primaryHover },
                }}
              >
                <FilterListIcon />
              </IconButton>

              <DateRangeIndicator
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                minDateRangeLocal={minDateRangeLocal}
                maxDateRangeLocal={maxDateRangeLocal}
                handleReset={handleReset}
                onCustomDateRangeSelect={handleCustomDateRangeSelect}
                isCustomRange={isCustomDateRange}
              />
            </Box>
          )}

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              mt: 1,
            }}
          >
            {isMobile && (
              <StyledIconButton
                onClick={handlePrev}
                visibility={currentIndex !== 0}
              >
                <ChevronLeftIcon />
              </StyledIconButton>
            )}

            {isMobile ? (
              <ItemFieldset>
                <ItemLegend>{items[currentIndex].legend}</ItemLegend>
                <ValueTypography color={items[currentIndex].color}>
                  {items[currentIndex].value}
                </ValueTypography>
              </ItemFieldset>
            ) : (
              items.map((item, index) => (
                <ItemFieldset key={index}>
                  <ItemLegend>{item.legend}</ItemLegend>
                  <ValueTypography color={item.color}>
                    {item.value}
                  </ValueTypography>
                </ItemFieldset>
              ))
            )}

            {isMobile && (
              <StyledIconButton
                onClick={handleNext}
                visibility={currentIndex !== items.length - 1}
              >
                <ChevronRightIcon />
              </StyledIconButton>
            )}
          </Box>
        </Box>

        <Modal open={modalOpen} onClose={closeModal}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: 'auto',
              marginTop: '50px',
            }}
          >
            <Box
              sx={{
                width: { xs: '90vw', sm: '80vw', md: '60vw' },
                maxHeight: '90vh',
                backgroundColor: 'white',
                borderRadius: 6,
                boxShadow: colors.shadow,
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <TimeTabsModal
                onTimePeriodChange={onTimePeriodChange}
                minDateRangeLocal={minDateRangeLocal}
                maxDateRangeLocal={maxDateRangeLocal}
                handleReset={handleReset}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                closeModal={closeModal}
                selectedMobileShortcut={mobileShortcut}
                onMobileShortcutSelect={handleMobileShortcutSelect}
                onCustomDateRangeSelect={handleCustomDateRangeSelect}
                clearDesktopFilters={clearDesktopFilters}
                modalOpen={modalOpen}
              />
            </Box>
          </Box>
        </Modal>
      </>
    );
  }
);

export default MetricsDisplay;
