import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Collapse,
  Typography,
  Box,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Receipt as ReceiptIcon,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import { colors } from '../../../../themes/theme';

const formatCategory = (category) => {
  return category
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const MobileTrxList = ({ transactions, onEdit, onDelete, onUploadReceipt }) => {
  const [openItemId, setOpenItemId] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [transactionToDelete, setTransactionToDelete] = useState(null);

  const handleToggle = (id) => {
    setOpenItemId(openItemId === id ? null : id);
  };

  const handleDeleteClick = (transaction) => {
    setTransactionToDelete(transaction);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (transactionToDelete) {
      onDelete(transactionToDelete.id);
    }
    setDeleteConfirmOpen(false);
    setTransactionToDelete(null);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setTransactionToDelete(null);
  };

  if (transactions.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          p: 3,
        }}
      >
        <Typography variant="body1" color="text.secondary" align="center">
          No transactions available for this period.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {transactions.map((transaction) => (
          <React.Fragment key={transaction.id}>
            <ListItem
              button
              onClick={() => handleToggle(transaction.id)}
              sx={{
                borderLeft: '4px solid',
                borderLeftColor:
                  transaction.amount >= 0 ? 'success.main' : 'error.main',
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle1" component="div">
                    {transaction.description || 'No Description'}
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color={
                        transaction.amount >= 0 ? 'success.main' : 'error.main'
                      }
                    >
                      {transaction.amount < 0 ? '-' : ''}$
                      {Math.abs(transaction.amount).toFixed(2)}
                    </Typography>

                    {' • '}
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.secondary"
                    >
                      {dayjs(transaction.date).format('MMM D, YYYY')}
                    </Typography>
                  </React.Fragment>
                }
              />
              {openItemId === transaction.id ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={openItemId === transaction.id}
              timeout="auto"
              unmountOnExit
            >
              <Box sx={{ p: 2, pl: 4, bgcolor: 'action.hover' }}>
                <Typography variant="body2" gutterBottom>
                  Account: {transaction.account}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Category: {formatCategory(transaction.category)}
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => onEdit(transaction.id)}
                    sx={{ color: colors.primary }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteClick(transaction)}
                    sx={{ color: colors.primary }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="receipt"
                    onClick={() => onUploadReceipt(transaction.id)}
                    sx={{ color: colors.primary }}
                  >
                    <ReceiptIcon />
                  </IconButton>
                </Box>
              </Box>
            </Collapse>
            <Divider />
          </React.Fragment>
        ))}
      </List>

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this transaction? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MobileTrxList;
