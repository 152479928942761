import React, { useState, useMemo } from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Search as SearchIcon, Sort as SortIcon } from '@mui/icons-material';
import {
  addTransaction,
  editTransaction,
  markTransactionAsRemoved,
} from '../../services/api/transactions-service.js';
import AddTrxFab from './Mobile Trx List/AddTrxFab';
import MobileTrxList from './Mobile Trx List/MobileTrxList';
import TrxModal from './Mobile Trx List/TrxModal';
import ReceiptModal from './ReceiptModal';
import TransactionFilter from './transactionFilter';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const MobileTransactionsPage = ({
  rows,
  setRows,
  accounts,
  setOpenAlert,
  setAlertSeverity,
  setAlertMessage,
  categories,
  timePeriod,
  selectedWeeks,
  selectedMonths,
  selectedQuarters,
  selectedYears,
  startDate,
  endDate,
  reloadTransactions,
  toggleValue,
  setToggleValue,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [currentReceiptUrl, setCurrentReceiptUrl] = useState(null);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('date');
  const [sortDirection, setSortDirection] = useState('desc');
  const [showSearch, setShowSearch] = useState(false);
  const [showSort, setShowSort] = useState(false);

  const handleAddClick = () => {
    setCurrentTransaction(null);
    setModalOpen(true);
  };

  const handleEdit = (id) => {
    const transaction = rows.find((t) => t.id === id);
    setCurrentTransaction(transaction);
    setModalOpen(true);
  };

  const categoryGroups = useMemo(
    () => ({
      income: [
        {
          title: 'INCOME',
          categories: [
            'Sales',
            'Shipping & Delivery Income',
            'Other Income',
            'Uncategorized Income',
          ],
        },
      ],
      expense: [
        {
          title: 'OPERATING EXPENSES',
          categories: [
            'Dues & Subscriptions',
            'Event Fees',
            'Insurance - Vehicles',
            'Meals and Entertainment',
            'Office Supplies',
            'Professional Fees',
            'Payroll - Salary & Wages',
            'Rent Expense',
            'Repairs & Maintenance',
            'Telephone - Land Line',
            'Travel Expense',
            'Utilities',
            'Vehicle - Fuel',
            'Vehicle - Repairs & Maintenance',
            'Uncategorized Expense',
          ],
        },
        {
          title: 'COST OF GOODS',
          categories: [
            'Food Cost',
            'Product Samples',
            'Purchases - Parts & Materials',
            'Purchases - Resale Items',
          ],
        },
      ],
    }),
    []
  );

  const handleSave = async (transactionData) => {
    console.log('Saving transaction:', transactionData);
    try {
      let response;
      const formattedData = {
        ...transactionData,
        date: dayjs(transactionData.date).toISOString(),
        category_id: categories.categoryIdMap[transactionData.category]?.id,
        amount: parseFloat(transactionData.amount),
      };

      if (currentTransaction) {
        response = await editTransaction(currentTransaction.id, formattedData);
      } else {
        response = await addTransaction(formattedData);
      }
      console.log('API response:', response);

      if (response.status === 200) {
        setAlertSeverity('success');
        setAlertMessage(
          currentTransaction
            ? 'Transaction successfully modified.'
            : 'Transaction successfully added.'
        );
        await reloadTransactions();
        setOpenAlert(true);
        setModalOpen(false);
      } else {
        throw new Error('Unexpected response status: ' + response.status);
      }
    } catch (error) {
      console.error('Error saving transaction:', error);
      console.error('Error details:', error.response?.data);
      setAlertSeverity('error');
      setAlertMessage(
        `Unable to ${
          currentTransaction ? 'modify' : 'add'
        } transaction. Please try again.`
      );
      setOpenAlert(true);
    }
  };

  const handleDelete = async (id) => {
    try {
      await markTransactionAsRemoved(id);
      await reloadTransactions();
      setAlertSeverity('success');
      setAlertMessage('Transaction successfully removed.');
      setOpenAlert(true);
    } catch (error) {
      setAlertSeverity('error');
      setAlertMessage('Unable to remove transaction. Please try again.');
      setOpenAlert(true);
    }
  };

  const handleUploadReceipt = (id) => {
    const transaction = rows.find((row) => row.id === id);
    const receiptUrl = transaction?.receiptUrl || null;
    setCurrentRowId(id);
    setCurrentReceiptUrl(receiptUrl);
    setReceiptModalOpen(true);
  };

  const handleCloseReceiptModal = () => {
    setReceiptModalOpen(false);
    setCurrentRowId(null);
    setCurrentReceiptUrl(null);
    setTimeout(() => {
      document.body.removeAttribute('style');
    }, 0);
  };

  const handleToggleChange = (event) => {
    const filter = event.target.value;
    setToggleValue(filter);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortField(event.target.value);
  };

  const filteredAndSortedRows = useMemo(() => {
    let result = [...rows];

    // Filter by time period
    result = result.filter((row) => {
      const rowDate = dayjs(row.date);
      switch (timePeriod) {
        case 'week':
          return selectedWeeks.some((week) => {
            const [start, end] = week.split(' - ');
            return rowDate.isBetween(dayjs(start), dayjs(end), null, '[]');
          });
        case 'month':
          return selectedMonths.includes(rowDate.format('MMMM YYYY'));
        case 'quarter':
          return selectedQuarters.includes(
            `Q${rowDate.quarter()} ${rowDate.year()}`
          );
        case 'year':
          return selectedYears.includes(rowDate.year().toString());
        case 'all':
          return rowDate.isBetween(
            dayjs(startDate),
            dayjs(endDate),
            null,
            '[]'
          );
        default:
          return true;
      }
    });

    // Filter by toggleValue
    if (toggleValue === 'income') {
      result = result.filter((row) => row.amount >= 0);
    } else if (toggleValue === 'expenses') {
      result = result.filter((row) => row.amount < 0);
    }

    // Filter by search term
    if (searchTerm) {
      result = result.filter((row) =>
        Object.values(row).some((value) =>
          value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // Sort
    result.sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    return result;
  }, [
    rows,
    timePeriod,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
    toggleValue,
    searchTerm,
    sortField,
    sortDirection,
  ]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          height: 'calc(100vh - 150px)',
          width: '80vw',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          overflow: 'hidden',
          boxShadow: 3,
        }}
      >
        <AppBar
          position="static"
          sx={{
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            overflow: 'hidden',
          }}
        >
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Transactions
            </Typography>
            <IconButton
              color="inherit"
              onClick={() => setShowSearch(!showSearch)}
            >
              <SearchIcon />
            </IconButton>
            <IconButton color="inherit" onClick={() => setShowSort(!showSort)}>
              <SortIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {showSearch && (
          <Box sx={{ p: 2, backgroundColor: 'background.paper' }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search transactions..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
        )}

        {showSort && (
          <Box sx={{ p: 2, backgroundColor: 'background.paper' }}>
            <TextField
              select
              fullWidth
              value={sortField}
              onChange={handleSortChange}
              label="Sort by"
              sx={{ mb: 2 }}
            >
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="description">Description</MenuItem>
              <MenuItem value="amount">Amount</MenuItem>
            </TextField>
            <ToggleButtonGroup
              value={sortDirection}
              exclusive
              onChange={(event, newDirection) => {
                if (newDirection !== null) {
                  setSortDirection(newDirection);
                }
              }}
              aria-label="Sort direction"
              fullWidth
            >
              <ToggleButton value="asc" aria-label="Ascending">
                Ascending
              </ToggleButton>
              <ToggleButton value="desc" aria-label="Descending">
                Descending
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}

        <Box sx={{ p: 2, backgroundColor: 'background.paper' }}>
          <TransactionFilter
            toggleValue={toggleValue}
            handleToggleChange={handleToggleChange}
          />
        </Box>

        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <MobileTrxList
            transactions={filteredAndSortedRows}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onUploadReceipt={handleUploadReceipt}
          />
        </Box>

        <AddTrxFab onClick={handleAddClick} />

        <TrxModal
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          transaction={currentTransaction}
          onSave={handleSave}
          accounts={accounts}
          categories={categories}
          categoryGroups={categoryGroups}
        />

        {receiptModalOpen && (
          <ReceiptModal
            transactionId={currentRowId}
            open={receiptModalOpen}
            handleClose={handleCloseReceiptModal}
            receiptUrl={currentReceiptUrl}
            reloadTransactions={reloadTransactions}
            setAlertMessage={setAlertMessage}
            setAlertSeverity={setAlertSeverity}
            setOpenAlert={setOpenAlert}
            transactionDescription={
              rows.find((row) => row.id === currentRowId)?.description || ''
            }
            transactionDate={
              rows.find((row) => row.id === currentRowId)?.date || ''
            }
            transactionCategory={
              rows.find((row) => row.id === currentRowId)?.category || ''
            }
            transactionAccount={
              rows.find((row) => row.id === currentRowId)?.account || ''
            }
            transactionAmount={
              rows.find((row) => row.id === currentRowId)?.amount || ''
            }
          />
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default MobileTransactionsPage;
