import React from 'react';
import { Fab } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

const AddTrxFab = ({ onClick }) => {
  return (
    <Fab
      color="primary"
      aria-label="add"
      onClick={onClick}
      sx={{ position: 'fixed', bottom: 16, right: 16 }}
    >
      <AddIcon />
    </Fab>
  );
};

export default AddTrxFab;
