import React, { useContext, useEffect } from 'react';
import {
  Box,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Link,
  LinearProgress,
} from '@mui/material';
import PlaidLink from '../features/PlaidLink/PlaidLink';
import { LinkButton } from '../features/PlaidLink/style.js';
import { colors } from '../../themes/theme';
import { WebSocketContext } from './contexts/WebSocketContext.js';
import { useNavigate } from 'react-router-dom';

const LinkBankAccountModal = ({
  open,
  handleClose,
  loadingData,
  setLoadingData,
}) => {
  const { syncing, syncCompleted } = useContext(WebSocketContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (syncCompleted) {
      navigate('/dashboard');
    }
  }, [syncCompleted, handleClose, navigate]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          padding: 2,
          borderRadius: '12px',
          backgroundColor: colors.white,
        },
      }}
    >
      <DialogTitle
        sx={{ color: colors.black, padding: '16px 24px', textAlign: 'center' }}
      >
        <Typography variant="h4">Link Your Bank Account</Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: '24px', textAlign: 'center' }}>
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
          Securely connect with your bank accounts using{' '}
          <Link
            href="https://plaid.com"
            target="_blank"
            rel="noopener"
            sx={{
              color: colors.primary,
              textDecoration: 'underline',
            }}
          >
            Plaid
          </Link>
          . <br />
          <Link
            href="https://onebitapp.com"
            target="_blank"
            rel="noopener"
            sx={{
              color: colors.primary,
              textDecoration: 'underline',
            }}
          >
            ONEBIT
          </Link>{' '}
          leverages Plaid to provide insights into your business's expenses and
          transactions.
        </Typography>

        {loadingData || syncing ? (
          <>
            <Typography variant="body2" sx={{ marginTop: '16px' }}>
              {loadingData
                ? 'Linking your bank account...'
                : 'Syncing your transactions...'}
            </Typography>

            {syncing && (
              <Box sx={{ marginTop: 2, width: '100%' }}>
                <LinearProgress />
              </Box>
            )}
          </>
        ) : (
          <Box sx={{ marginTop: '16px' }}>
            <PlaidLink
              loadingData={loadingData}
              setLoadingData={setLoadingData}
              onSuccess={handleClose}
            />
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ padding: '16px' }}>
        <Tooltip title="Cancel" placement="top" arrow>
          <span>
            <LinkButton
              onClick={handleClose}
              disabled={syncing}
              sx={{
                borderRadius: '20px',
                backgroundColor: syncing ? colors.lightGray : colors.primary,
                color: colors.white,
                padding: '8px 24px',
              }}
            >
              Cancel
            </LinkButton>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default LinkBankAccountModal;
