import React from 'react';
import { DGExportButton } from '../../styles/style.js';
import { SButton } from '../../styles/style.js';
import { randomId } from '@mui/x-data-grid-generator';
import PlaidLink from '../PlaidLink/PlaidLink.js';
import AddIcon from '@mui/icons-material/Add';
import { GridRowModes } from '@mui/x-data-grid';
import {
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CustomGridToolbarContainer } from '../../styles/style.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDashboardContext } from '../../common/contexts/DashboardContext.js';
import TransactionFilter from './transactionFilter.js';

dayjs.extend(utc);

const EditToolbar = ({
  setRows,
  setRowModesModel,
  loadingData,
  setLoadingData,
  setEditCount,
  onFilterChange,
  toggleValue,
  handleToggleChange,
}) => {
  const {
    setActiveTimeTab,
    setToggleValue,
    setSelectedWeeks,
    setSelectedMonths,
    setSelectedQuarters,
    setSelectedYears,
    setStartDate,
    setEndDate,
    minDateRangeLocal,
    maxDateRangeLocal,
  } = useDashboardContext();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleClick = async () => {
    if (loadingData) {
      return;
    }

    setLoadingData(true);

    setRows((oldRows) => {
      const hasUnsaved = oldRows.some((row) => row.isNew);
      if (hasUnsaved) {
        setLoadingData(false);
        return oldRows;
      }

      const id = randomId();
      const localDate = new Date();
      const utcDate = dayjs(localDate).utc().format();

      const newRow = {
        id,
        date: utcDate,
        description: '',
        account: 'Other',
        category: '',
        amount: 0,
        isNew: true,
      };
      setActiveTimeTab('all');
      setToggleValue('All');
      setSelectedWeeks([]);
      setSelectedMonths([]);
      setSelectedQuarters([]);
      setSelectedYears([]);
      setStartDate(dayjs(minDateRangeLocal).format('YYYY-MM-DD'));
      setEndDate(dayjs(maxDateRangeLocal).format('YYYY-MM-DD'));
      onFilterChange('All');

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'date' },
      }));
      setEditCount((prevCount) => prevCount + 1);
      setLoadingData(false);

      return [newRow, ...oldRows];
    });
  };

  const mobileLayout = (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          width: '100%',
          mb: 2,
          textAlign: 'center',
        }}
      >
        Transactions
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          mb: 2,
        }}
      >
        <PlaidLink loadingData={loadingData} setLoadingData={setLoadingData} />
        <DGExportButton
          printOptions={{
            print: true,
            fields: ['date', 'account', 'category', 'amount'],
            hideFooter: true,
            hideToolbar: true,
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: 2,
        }}
      >
        <TransactionFilter
          toggleValue={toggleValue}
          handleToggleChange={handleToggleChange}
        />
        <Tooltip title="Add Transaction" placement="top" arrow>
          <SButton
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleClick}
            sx={{ height: '40px', width: '100%' }}
          >
            Add Transaction
          </SButton>
        </Tooltip>
      </Box>
    </Box>
  );

  const desktopLayout = (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}>
        <Typography variant="h6" sx={{ mr: 2 }}>
          Transactions
        </Typography>
        <TransactionFilter
          toggleValue={toggleValue}
          handleToggleChange={handleToggleChange}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Tooltip title="Add Transaction" placement="top" arrow>
          <SButton
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleClick}
            sx={{ width: '180px' }}
          >
            Add Transaction
          </SButton>
        </Tooltip>
        <PlaidLink loadingData={loadingData} setLoadingData={setLoadingData} />
        <DGExportButton
          printOptions={{
            print: true,
            fields: ['date', 'account', 'category', 'amount'],
            hideFooter: true,
            hideToolbar: true,
          }}
        />
      </Box>
    </Box>
  );

  return (
    <CustomGridToolbarContainer sx={{ width: '100%', padding: '16px' }}>
      {isDesktop ? desktopLayout : mobileLayout}
    </CustomGridToolbarContainer>
  );
};

export default EditToolbar;
